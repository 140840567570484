.table{
  overflow: auto;
  table-layout: auto !important;
}

.react-date-picker{
  width:100%;
  min-height: 38px;
}

.react-date-picker__wrapper {
  border-color: hsl(0,0%,80%)!important;
}
